@import "../../../style/colors.scss";
@import "../../../style/mixins.scss";
@import "../../../style/sizes.scss";
@import "../../../style/spacing.scss";
@import "../../../style/z-index.scss";
@import "../../../style/typography.scss";

.header {
  width: 100%;
  position: fixed;
  height: $headerHeight;
  z-index: $header;

  .headerContent {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: $spacing-s;

    .logoContainer {
      display: flex;
      align-items: center;
    }

    .menuContainer {
      margin-top: $spacing-s;
      border-left: 1px solid $borderColorLight;
      height: 5rem;
      display: flex;
      align-items: center;
    }
  }

  .logo {
    width: 12rem; //temp
    margin: $spacing-xs $spacing-m 0 $spacing-s;
  }
}
