@import "../../../style/colors.scss";
@import "../../../style/mixins.scss";
@import "../../../style/sizes.scss";
@import "../../../style/spacing.scss";
@import "../../../style/typography.scss";
@import "../../../style/z-index.scss";

.menuWrapper {
  position: relative;
  padding: 0 $spacing-m;
  min-width: 20rem;

  .title {
    color: $primary;
  }

  .icon {
    margin-left: $spacing-m;
  }

  .submenuWrapper {
    position: absolute;
    padding-top: $spacing-m;
    min-width: 300px;

    .menu {
      background: $white;
      z-index: $flyout;
      position: relative;
      border-radius: 2px;
      border: 1px solid $borderColorLight;

      .searchBar {
        display: flex;
        align-items: center;

        .searchIcon {
          display: flex;
          position: absolute;
          left: 2rem;
        }

        .input {
          height: 2rem;
          width: 100%;
          margin: $spacing-m;
          border-style: solid;
          border-width: 1px;
          border-color: $primary;
          border-radius: $borderRadius;
          padding: $spacing-m;
          color: currentColor;
          text-indent: $spacing-m;

          &::placeholder {
            opacity: 1;
          }
          &:focus::placeholder {
            opacity: 1;
          }

          &:hover {
            border-color: $primaryDark;
          }
          &:focus,
          &:active,
          &:focus-within {
            outline-width: 1px;
            outline-color: $primaryDark;
          }
        }
      }

      .list {
        max-height: 80vh;
        overflow-y: auto;
        padding-top: unset;

        .noResults {
          margin-left: $spacing-m;
        }

        @include small-screen {
          max-height: 55vh;
        }
      }

      ul {
        padding: $spacing-m;
      }

      small {
        padding: 0 $spacing-m $spacing-m $spacing-m;
      }
    }
  }
}
