@import "../../../../style/spacing.scss";
@import "../../../../style/typography.scss";
@import "../../../../style/colors.scss";

.item {
  font-weight: $fontWeightLight;
  margin: 0 $spacing-xs $spacing-xs $spacing-m;
  white-space: nowrap;

  .title {
    display: flex;
    align-items: center;
  }

  .check {
    margin-left: 1rem;
  }
}

.active {
  color: $primary;
}
