@import "../../../../style/colors.scss";
@import "../../../../style/sizes.scss";
@import "../../../../style/spacing.scss";
@import "../../../../style/typography.scss";

li {
  padding: $spacing-s;

  a {
    color: inherit;
    text-decoration: none;
    &:hover {
      color: $affordance;
    }
  }
  > * {
    width: 100%;
  }
}

.row {
  font-weight: $fontWeightMedium;

  .title {
    white-space: nowrap;
  }
}
