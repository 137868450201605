@import "../../../../style/colors.scss";
@import "../../../../style/mixins.scss";
@import "../../../../style/sizes.scss";
@import "../../../../style/spacing.scss";
@import "../../../../style/typography.scss";
@import "../../../../style/z-index.scss";

.container {
  padding-left: 1rem;
}

.menuWrapper {
  .submenuWrapper {
    position: absolute;
    padding-top: $spacing-m;
    width: fit-content;

    .menu {
      background: $white;
      z-index: $flyout;
      position: relative;
      border-radius: 2px;
      border: 1px solid $borderColorLight;

      .list {
        max-height: 80vh;
        overflow-y: auto;
        font-size: $fontSizeSmall;

        @include small-screen {
          max-height: 55vh;
        }
      }

      ul {
        padding: $spacing-m;
      }

      small {
        padding: 0 $spacing-m $spacing-m $spacing-m;
      }

      li {
        padding: $spacing-s;

        a {
          color: inherit;
          text-decoration: none;
          &:hover {
            color: $affordance;
          }
        }
        > * {
          width: 100%;
        }
      }
    }
  }
}
