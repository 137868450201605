/* File has been copied */

$pseudoElement: -1;
$subMenu: 1;
$header: 2;
$flyout: 3;
$drawer: 3;
$popup: 5;
$footerMenu: 4;
$errorMessage: 6;
